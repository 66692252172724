<template>
  <v-container>
    <v-skeleton-loader
      type="table"
      v-if="!data_loaded"
      class="mt-10"
    ></v-skeleton-loader>
    <!--/ sekeletion -->

    <v-card elevation="0" class="mt-10" v-if="data_loaded">
      <v-card-title class="font-weight-bold"
        >المحلات
        <v-spacer></v-spacer>
        <v-btn to="/Store" color="primary">اضافة محل</v-btn>
      </v-card-title>
      <v-data-table
        :mobile-breakpoint="0"
        :footer-props="$store.state.footer_props"
        :items="stores"
        :headers="headers"
      >
        <template v-slot:[`item.logo`]="{ item }">
          <v-avatar width="50" class="my-2">
            <img :src="`${$store.state.def_path}/${item.logo}`" />
          </v-avatar>
        </template>
        <!--/ logo -->

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            target="_blank"
            :href="`https://menyo.co/${item.id}`"
            icon
            small
          >
            <v-icon> visibility </v-icon>
          </v-btn>


          <v-btn
            :to="`/Categories?store=${item.id}&store_name=${item.name}`"
            small
            icon
          >
            <v-icon small>category</v-icon>
          </v-btn>

          <v-btn
            :to="`/Products?store=${item.id}&store_name=${item.name}`"
            small
            icon
          >
            <v-icon small>lunch_dining</v-icon>
          </v-btn>

          <v-btn :to="{ name: 'store', params: { id: item.id } }" small icon>
            <v-icon small>edit</v-icon>
          </v-btn>
          <v-btn small icon @click="open_delete_dialog(item.id)">
            <v-icon small>delete</v-icon>
          </v-btn>
        </template>
        <!--/ actions -->
      </v-data-table>
      <!--/ datatable -->
    </v-card>
    <!--/ card -->

    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block"> حذف المحل ؟ </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            @click="delete_sotre()"
            depressed
            color="error"
            >حذف</v-btn
          >
          <v-btn @click="delete_dialog = false" depressed>الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->

    <v-snackbar color="success" left v-model="snackbar_success">
      <p>تم حذف المحل</p>
      <template v-slot:action>
        <v-icon color="white" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>
  </v-container>
  <!--/ container -->
</template>

<script>
export default {
  name: "employees",

  data() {
    return {
      snackbar_success: false,
      delete_dialog: false,
      delete_loader: false,
      delete_id: null,
      data_loaded: false,
      headers: [
        {
          value: "name",
          text: "اسم الشركة",
          align: "center",
        },
        {
          value: "logo",
          text: "الشعار",
          align: "center",
        },
        {
          value: "phone_number",
          text: "رقم صاحب المحل",
          align: "center",
        },
        {
          value: "country",
          text: "البلد",
          align: "center",
        },
        {
          value: "actions",
          text: "أجراءات",
          align: "center",
        },
      ],
      stores: [],
    };
  },
  methods: {
    get_stores() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "Stores",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;
          this.stores = Object.assign([], res.data.data);
        });
    },
    open_delete_dialog(id) {
      this.delete_id = id;
      this.delete_dialog = true;
    },
    delete_sotre() {
      if (!this.delete_loader) {
        this.delete_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `Stores/${this.delete_id}`,
              method: "delete",
            },
          })
          .then((res) => {
            let store_index = this.stores.findIndex(
              (e) => e.id == this.delete_id
            );
            this.stores.splice(store_index, 1);
            this.delete_loader = false;
            this.delete_dialog = false;
            this.snackbar_success = true;
          });
      }
    },
  },
  mounted() {
    this.get_stores();
  },
};
</script>
